// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Carousel from 'antd/lib/carousel'
import 'antd/lib/carousel/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../components/link'
import '../components/link/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import { updateWebsiteState as updateWebsiteStateFx } from '../state/actions'

import AboutPageSchema from '../components/schema/about-page-schema'
import ContactPageSchema from '../components/schema/contact-page-schema'

import ArrowLeft from '../components/svg/arrow-left'
import ArrowRight from '../components/svg/arrow-right'

import about from '../seo/about.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'About',
  slug: '/about',
  abstract: about.text,
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'About', slug: '/about' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img1: file(relativePath: { eq: "book/img1.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img2: file(relativePath: { eq: "book/img2.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img3: file(relativePath: { eq: "book/img3.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img4: file(relativePath: { eq: "book/img4.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img5: file(relativePath: { eq: "book/img5.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img6: file(relativePath: { eq: "book/img6.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img7: file(relativePath: { eq: "book/img7.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = ({ websiteState, updateWebsiteState, ...props }) => {
  const resources = [
    { img: props.data.img1 },
    { img: props.data.img2 },
    { img: props.data.img3 },
    { img: props.data.img4 },
    { img: props.data.img5 },
    { img: props.data.img6 },
    { img: props.data.img7 },
  ]

  const sliderConf = {
    infinite: false,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    customPaging: (i) => {
      return (
        <a aria-label={`Go to slide ${i + 1}`} role="link">
          <GatsbyImage image={getImage(resources[i].img)} />
        </a>
      )
    },
    dotPosition: 'bottom',
  }

  return (
    <StandardPageWrapper pageSchema={pageSchema} {...props}>
      <AboutPageSchema />
      <ContactPageSchema />
      <h1>About this offering</h1>
      <p>
        The Matrimandir Journals are a search into the meaning and purpose of
        this vibrant spiritual symbol that is the centre of Auroville.
      </p>
      <p className="text-indent">
        The Matrimandir seeks to embody Auroville’s soul and exemplify its
        aspiration to fashion “willing servitors of the divine consciousness”.
        From this concentrated wellspring, a dynamic peace radiates into the
        myriad activities of the township encircling it. Commenting upon
        symbols, Sri Aurobindo declares: "A spiritual symbol is only a
        meaningless ticket, unless the thing symbolised is realised in the
        spirit ... A spiritual idea is a power, but only when it is both
        inwardly and outwardly creative."
      </p>
      <p className="text-indent">
        The Auroville experience demands an aeskesis that can be built only upon
        a secure foundation of balance, poise, and equilibrium. A dynamic
        equality is the key to building the new consciousness that is
        Auroville’s intention. However, there are paradoxes that need to be
        worked through to create the harmonious growth of consciousness in those
        who dedicate their lives to this experiment.
      </p>
      <p className="text-indent">
        Auroville’s Charter offers a programme of self-development that a
        self-centred, egoistic human consciousness is not able to embody. The
        city is, therefore, a place of “research, study and experimentation”
        attempting to bring about a “collective realisation” manifesting “a
        higher and truer life”.
      </p>
      <p className="text-indent">
        Yet these great idea-forces were called upon to be manifested upon a
        land subjected to forces of advanced desertification — a barren,
        wind-swept, red-laterite plateau. The first steps of Auroville’s
        material manifestation were grounded in the bleak actuality of available
        conditions.
      </p>
      <p className="text-indent">
        Who were the pioneers? How did they turn this red dustiness to the green
        and flourishing tropical dry evergreen forest that stands today as a
        vibrant living example on how to redeem a seemingly lost landscape? In
        the non-hierarchical setting of this collectivity, with no outward
        ‘command centre’, what power held these early settlers together? How did
        they work through their multitudinous differences?
      </p>
      <p className="text-indent">
        What force bound them to what seemed a distant purpose?
      </p>
      <p className="text-indent">
        Are there answers to be found in Sri Aurobindo and the Mother’s
        writings?
      </p>
      <p className="text-indent">
        It is Matrimandir, materially, psychologically, and spiritually that
        gives Auroville its true scope and dimension. Physically it occupies a
        substantial area of the township. Psychologically it embodies the point
        of Unity: The Mother said it was “the central force — the force of
        cohesion in Auroville”. Spiritually, it is both “the Soul of Auroville”
        as also “the living symbol of Auroville's aspiration for the Divine”.
      </p>
      <p className="text-indent">
        From its inception, the Matrimandir construction site has held a
        singular place in the evolution of this fledgling collectivity. Whatever
        else they were engaged upon in their daily lives, Aurovilians came
        together bodily to work together upon the structure. As the edifice rose
        from its foundation crater, it demanded long, sometimes continuous
        day/night concretings: The 4 pillars upon which the spherical structure
        stands, the different floors, followed by the spaceframe on which the
        golden discs are attached, all was the work of Aurovilians. There was
        then the regular scraping of the iron scaffolding, repeatedly removed
        and reused after polishing in new locations. Many hands, many moments
        filled with a sense of oneness, joy, and laughter; as of a single being
        in many bodies. Building Matrimandir has been labour of love, shaping
        the body for the soul of the city. It has invited the physical labour of
        hundreds of Aurovilians and the financial contributions of thousands of
        devoted beings from every corner of the world.
      </p>
      <p className="text-indent">
        Today, the innumerable activities of this ‘city of dawn’ spiral outward
        from a tranquil heart of dynamic peace.
      </p>
      <p className="text-indent">
        The Matrimandir Journals look at these truths as also remarks of the
        Mother and Sri Aurobindo while exploring the inward psychological and
        spiritual significance of the elements that compose the centre.
        Elucidating the first condition of Auroville’s Charter: "To live in
        Auroville one must be the willing servitor of the Divine Consciousness",
        Mother explained that the Matrimandir symbolised the Divine
        Consciousness. The Journals bring forward a selection from the numerous
        splendours revealed by so comprehensive a spiritual symbol.
      </p>
      <h2>The print web-book</h2>
      <div className="slider fullpage book-slider flow">
        <Carousel {...sliderConf}>
          {resources.map((resource) => (
            <GatsbyImage image={getImage(resource.img)} />
          ))}
        </Carousel>
      </div>
      <h2>Web book, this format</h2>
      <p>
        <i>{about.altTitle}</i>&nbsp;is published as part of an emerging method
        of publishing being developed by Prisma Books. The idea is to deliver
        the high level of writing and design quality that you would find in a
        printed book, and to use advanced web technologies for publishing. This
        is the "web-book" format.
      </p>
      <p>
        <Link to="https://books.prisma.haus/en/web-books">
          Read more about web books →
        </Link>
      </p>
      <h2 id="on-gift-economy">On gift economy</h2>
      <p>
        You can buy the paperback version of the book <i>{about.altTitle}</i> at
        a fixed price. The author and the publisher have also chosen to offer
        the book to read online without a pay-wall or advertisement. This is not
        for free, but available on a Gift-Economy and Pay-What-Feels-Right
        basis. It is an invitation to give what feels right for the value you've
        received from the book and/or the support you want to give us to
        continue doing our work. Publishing in this manner is an experiment in
        abundance where we trust that when we give, we will also receive. We
        want you to enjoy the book, learn from the book, but also support the
        book. If it was worth your time, please consider buying the book or
        contributing.
      </p>
      <p className="text-indent">
        <Link to="https://books.prisma.haus/en/available-on-gift-economy">
          Learn more about publishing on Gift-Economy →
        </Link>
      </p>
      <h2>Prisma Books, the publisher</h2>
      <p>
        Since 1988 Prisma Books of Auroville publishes good old-fashioned,
        beautifully curated books. Prisma Books offers full publishing services
        in design, editorial, printing, artwork, illustrations, photography,
        digital media, exhibition and curatorship.
      </p>
      <p className="text-indent">
        <Link to="https://www.prisma.haus/en/">Prisma website →</Link>
      </p>
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    websiteState: state.websiteState,
  }),
  (dispatch) => ({
    updateWebsiteState(payload) {
      dispatch(updateWebsiteStateFx(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
